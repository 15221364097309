import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '@we-agile-you/retrospective-app';
import { Faqs } from '@we-agile-you/retrospective-app';

export default function FaqsTemplate() {
  return (
    <Layout>
      <Helmet title="Scrum poker | We Agile You" />
      <Faqs />
    </Layout>
  );
}
